<template>
  <div class="container">
    <Nav></Nav>
    <div class="content">
        <div class="grid">
            <div class="grid-item" v-for="(item,index) in imgList" :key="index">
                <img :src="item.url" alt=""/>
                <div class="message">
                    <div class="date">{{ item.date }}</div>
                    <div class="tips">{{ item.tips }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="contentPhone">
        <div class="item" v-for="(item,index) in imgList" :key="index">
            <img :src="item.url" alt=""/>
            <div class="message">
                <div class="date">{{ item.date }}</div>
                <div class="tips">{{ item.tips }}</div>
            </div>
        </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>
  
<script>
import Masonry from "masonry-layout";
import Nav from "../components/Nav";
import imagesloaded from 'imagesloaded';
import Footer from '../components/FooterInside.vue'
export default {
  components: {
    Nav,
    Footer
  },
  name: "Followind",
  data() {
    return {
      imgList: [
      {
          url:require("../assets/followind/2023HAPPY.jpg"),
          date: '2023',
          tips: '2023 HAPPY 兔耶！'
        },
        {
          url:require("../assets/followind/AP18.jpg"),
          date: '2022/08/19',
          tips: '入选亚太设计年鉴 APD’18'
        },
        {
          url:require("../assets/followind/xmhsj.jpg"),
          date: '2021/10/5',
          tips: '厦门好设计金奖'
        },
        
      ],
    };
  },
  methods: {},
  updated() {
    
  },
  mounted() {
    var grid = document.querySelector(".grid");
    var msnry = new Masonry(grid, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-item",
      percentPosition: true
    });
    imagesloaded('.grid-item', () => {
        msnry.layout(); // 图片加载完成后重新绘制。
    });
  },
};
</script>
  
  <style lang="less" scoped>
.container {
  position: relative;
  .footer{
    position: absolute;
    bottom: -350px;
  }
    .content{
        position: relative;
        margin: 0 auto;
        top: 1rem;
        padding: 0 0.2rem;
    }
    .grid-item { 
        width: 20%; 
        padding: 0 20px 0 0 ;
        box-sizing: border-box;
        img{
            width: 100%;
        }
        .message {
        padding-left: 0px;
        .date {
            font-size: 30px;
            margin-bottom: -5px;
        }
        .tips {
            font-size: 13px;
            color: #7e7e7e;
        }
        }
    }
}

.contentPhone{
      display: none;
    }
@media only screen and (max-width:768px) {
  .container{
    .footer{
    position: relative;
    bottom: 0;
  }
    .content{
      display: none;
    }
    .contentPhone{
      display: block;
    }
    .contentPhone{
      display: flex;
      flex-direction: column;
      align-items: center;
      .item{
        width: 100%; 
        padding: 0 10px;
        box-sizing: border-box;
        .message{
          font-size: 20px;
        }
        img{
          width: 100%;
        }
      }
      .item:nth-child(1) {
        margin-top: 100px
      }
    }
  }
}
</style>